import React from 'react';

import styles from './GameTermsSR.module.scss';

const GameTermsSR = () => {
	return (
		<div className={styles.game_terms}>
			<p className={styles.content}>
				Na osnovu članova 16., 23. i 115. Zakona o igrama na sreću („Službeni glasnik RS“, br. 88/2011, 93/2012
				- dr. zakon, 30/2018 i 95/2018), članova 2. i 5. Pravilnika o vrstama igara na sreću – Katalog igara na
				sreću, („Službeni glasnik RS“, br. 75/2013) i na osnovu Odluke o priređivanju nagradne igre u robi i
				uslugama „EKO Nagradna igra“, broj 1111-19/07 od 4. oktobra 2019. godine, Generalni direktor,{' '}
				<strong>EKO SERBIA AD BEOGRAD (NOVI BEOGRAD)</strong>, Vuk Radović utvrđuje sledeća:
			</p>
			<h2>PRAVILA</h2>
			<h2>NAGRADNE IGRE U ROBI I USLUGAMA</h2>
			<h2>„EKO Nagradna igra“</h2>
			<div className={styles.about_list_item}>
				<p className={styles.title}>1. Uvodne odredbe</p>
				<p className={styles.content}>
					1.1. Ovim Pravilima uređuje se priređivanje nagradne igre u robi i uslugama – (u daljem tekstu:
					Nagradna igra „<strong>EKO Nagradna igra</strong>“) i to način priređivanja igre, opis i vreme
					trajanja igre, područje priređivanja igre, uslovi za učestvovanje u igri, način i rokovi uručenja
					nagrada, način obaveštavanja učesnika o pravilima igre, kao i druga pitanja od značaja za
					priređivanje igre i učestvovanje u igri u skladu sa zakonom.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>2. Podaci o Priređivaču Nagradne igre</p>
				<p className={styles.content}>
					2.1. Priređivač Nagradne igre na koju se odnose ova Pravila je privredno društvo EKO SERBIA AD
					BEOGRAD (NOVI BEOGRAD) sa sedištem u Beogradu-Novi Beograd, Tošin bunar br. 274a, matični broj
					17413333, PIB 100118236, zakonski zastupnik: Vuk Radović, generalni direktor (u daljem tekstu:
					„Priređivač“).
				</p>
				<p className={styles.content}>
					2.2. Priređivač priređuje Nagradnu igru na osnovu Odluke Generalnog Direktora Priređivača br.
					1111-19/07 od 4. oktobra 2019. godine , a u cilju reklamiranja motornog benzina, dizel goriva, gasa,
					dodatnog asortimana i programa lojalnosti – EKO Smile kluba, a radi njihove bolje prezentacije
					potrošačima i ostvarivanja značajnijih komercijalnih efekata i poslovnih rezultata.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>3. Trajanje Nagradne igre i teritorija priređivanja</p>
				<p className={styles.content}>
					3.1. Nagradna igra se priređuje u periodu od 29. novembra 2019. godine do 28. decembra 2019. godine
					(zaključno sa izvlačenjem nagrada) i to isključivo na teritoriji Republike Srbije.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>4. Učesnici u Nagradnoj igri</p>
				<p className={styles.content}>
					4.1. Pravo na učešće u Nagradnoj igri imaju svi punoletni građani Republike Srbije, koji su članovi
					EKO SMILE Kluba, bez obzira da li su bili članovi ili su postali članovi EKO SMILE Kluba tokom ove
					nagradne igre, a koji prihvataju Pravila Nagradne igre.
				</p>
				<p className={styles.content}>
					4.2. U Nagradnoj igri ne mogu učestvovati zaposleni kod Priređivača, članovi njihove uže porodice
					(bračni drug, deca, braća, sestre, roditelji zaposlenog i roditelji bračnog druga zaposlenog), sva
					pravna lica koja sarađuju sa Priređivačem kao i lica koja su u stalnom radnom odnosu ili na drugi
					način radno angažovana kod pravnih lica koja sarađuju sa Priređivačem i/ili lica koja su u stalnom
					radnom odnosu ili na drugi način radno angažovana kod pravnih lica koja na bilo koji način učestvuju
					u priređivanju i organizaciji Nagradne igre, i/ili izvlačenju dobitaka, i/ili imaju pristup podacima
					vezanim za priređivanje Nagradne igre.
				</p>
				<p className={styles.content}>
					4.3. Ona pravna lica koja na Eko pumpama kupuju goriva i proizvode Eko business karticama i koriste
					Eko Smile karticu za sakupljanje bodova i registrovanje transakcija su izuzeta iz mogućnosti za
					osvajanje nagrada.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>5. Način ostvarivanja učestvovanja u Nagradnoj igri</p>
				<p className={styles.content}>5.1. Učestvovanje u Nagradnoj igri se ostvaruje na sledeći način:</p>
				<ul>
					<li>
						Učesnik u Nagradnoj igri je svako lice koje je član EKO SMILE KLUBA i koje izvrši transakciju
						registrovanu preko EKO SMILE kartice ili EKO SMILE mobilne aplikacije u periodu od 29. novembra
						2019. godine u 00:00 do 27. decembra 2019. godine do 23:59, u vidu kupovine na EKO pumpama na
						teritoriji Republike Srbije: minimum 15 litara PREMIUM goriva, tačnije motorni benzin “Racing
						Formula 98” ili dizel gorivo «Diesel Avio Double Filtered“ uz provlačenje SMILE kartice ili
						mobilne aplikacije za prikupljanje bodova*.
					</li>
					<li>
						Svaki učesnik u nagradnoj igri je dužan da ima preuzetu “EKO Smile” mobilnu aplikaciju, da je
						uspešno ulogovan, kako bi mogao da odigra igricu putem notifikacije koju će dobiti od
						Priređivača.
					</li>
					<li>
						Notifikacija se šalje jednom nedeljno, petkom tokom 4 nedelje, počev od 6. decembra 2019.
						godine, potrošačima koji su ispunili uslov kupovine minimum 15 litara PREMIUM goriva za
						prethodnih sedam dana i provukli “Smile” karticu ili mobilnu aplikaciju radi učitavanja poena i
						registracije transakcije.
					</li>
					<li>
						Učesnik u nagradnoj igri je zapravo svaka transakcija kojom je obavljena kupovina navedenih
						proizvoda u minimalnoj količini od 15 litara PREMIUM goriva, pri čemu se svaka transakcija kojom
						je obavljena kupovina u iznosu većim od 15 litara vodi kao jedna transakcija. Provlačenje SMILE
						kartice ili mobilne aplikacije za kupovinu navedenih proizvoda u predviđenom iznosu se
						automatski beleži kao jedna transakcija u sistemu koja nosi neki svoj broj. Taj broj na kraju
						učestvuje u izvlačenju. Potrošač može učestvovati u nagradnoj igri onoliko puta kolika ima
						ostvarenih pojedinačnih kupovina navedenih proizvoda u minimalnoj količini od 15 litara PREMIUM
						goriva, pri čemu je provukao SMILE karticu ili Smile aplikaciju.
					</li>
				</ul>
				<p className={styles.content}>
					* Učešće u nagradnoj igri se ne ostvaruje kupovinom u partnerskim restoranima na EKO pumpama:
					Roggenart i Olympus Plaza.
				</p>
				<p className={styles.content}>
					5.2. Učestvovanjem u Nagradnoj igri na način utvrđen u članu 5.1. Pravila smatraće se da je učesnik
					u Nagradnoj igri prihvatio u celosti prava i obaveze iz ovih Pravila.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>6. Izvlačenje nagrada i utvrđivanje dobitaka</p>
				<p className={styles.content}>
					6.1. Organizuje se nagradna igra putem kupovine naEKO pumpama na teritoriji Republike Srbije, u
					periodu od 29. novembra 2019. godine u 00:00 do 27. decembra 2019. godine do 23:59. Nedeljne nagrade
					se izvlače jednom nedeljno petkom za potrošače koji su u prethodnih sedam dana (zaključno sa
					četvrtkom do 23:59) ostvarili transakcije u minimalnom iznosu od 15 litara kupljenog PREMIUM goriva.
					Ukoliko je potrošač imao više transakcija tokom nedelje ima pravo da učestvuje toliko puta koliko je
					ostvario transakcija u minimalnom iznosu. U nedeljnom izvlačenju mogu učestovati sve pristigle
					transakcije sa dodeljenim brojem u sistemu, koji garantuje da su ispunjeni uslovi učešća, od
					prethodne nedelje zaključno sa nedeljom. Presek se vrši svakog petka a notifikacija potrošačima se
					šalje petkom. Prva notifikacija se šalje 6. decembra 2019. godine a poslednja notifikacija se šalje
					27. decembra 2019. godine za nedeljne nagrade a 28. decembra 2019. godine se šalju notifikacije za
					glavnu nagradu. Priređivač je obezbedio nedeljne nagrade koje će biti izvučene svakog petka za
					prethodnih sedam dana, dok će sve nedobijene transkacije u Nagradnoj igri, koji su ostvarili uslov
					kupovine od minimum 15 litara PREMIUM goriva u periodu od 29. novembra 2019. godine u 00:00 do 27.
					decembra 2019. godine do 23:59, imati pravo na učestvovanje za glavny nagrady u poslednjoj nedenji
					izvlačenja, tačnije 28. decembra 2019. godine s tim što transakcije u minimalnom iznosu od 15 litara
					kupljenog PREMIUM goriva realizovane na dan 27. decembra 2019. godine (odnosno od 00:00 do 23:59 tog
					dana) učestvuju samo za glavnu nagradu. Notifikacija vodi ka igrici koju je učesnik dužan da odigra.
					Ukoliko potrošač ne odigra igricu u roku od 24h Priređivač će ga kontaktirati u cilju obaveštenja o
					poslatoj notifikaciji.
				</p>
				<h2>MEHANIZAM IZVLAČENJA:</h2>
				<table className={styles.table}>
					<thead>
						<tr>
							<th>
								<p>
									<strong>Datum izvlačenja</strong>
								</p>
							</th>
							<th>
								<p>
									<strong>Broj nedeljih dobitnika</strong>
								</p>
							</th>
							<th>
								<p>
									<strong>Broj dobitnika glavne nagrade za dvoje</strong>
								</p>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<p>6.12.2019.</p>
							</td>
							<td>
								<p>1 telefon, 5 kartica sa 100 litara goriva</p>
							</td>
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td>
								<p>13.12.2019.</p>
							</td>
							<td>
								<p>1 telefon, 5 kartica sa 100 litara goriva</p>
							</td>
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td>
								<p>20.12.2019.</p>
							</td>
							<td>
								<p>1 telefon, 5 kartica sa 100 litara goriva</p>
							</td>
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td>
								<p>27.12.2019.</p>
							</td>
							<td>
								<p>1 telefon, 5 kartica sa 100 litara goriva</p>
							</td>
							<td>&nbsp;</td>
						</tr>
						<tr>
							<td>
								<p>28.12.2019. </p>
							</td>
							<td>&nbsp;</td>
							<td>
								<p>5 putovanja u Atinu za 2 оsobe</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p className={styles.content}>
					6.2. Dobitnici će biti izabrani na osnovu softvera, ali izvlačenje svih nagrada u Nagradnoj igri će
					nadzirati i kontrolisati tročlana komisija koja će kontrolisati i evidentirati tok izvlačenja
					nagrada i o tome sačiniti zapisnik. Komisiju će sačinjavati sledeća lica:
				</p>
				<ul>
					<li>lice imenovano od strane Priređivača;</li>
					<li>lice imenovano od strane Leo Burnett d.o.o.;</li>
					<li>lice imenovano od strane Sampro softverske kompanije koja pruža tu vrstu usluge;</li>
				</ul>
				<p className={styles.content}>
					6.3. Dobitnici Nagrada u Nagradnoj igri se izvlače nasumičnim softverskim odabiranjem iz baze
					registrovanih transakcija putem EKO Smile kartice i aplikacije za vreme trajanja nagradne igre.
				</p>
				<p className={styles.content}>
					6.4. Dobitnik nagrade je učesnik čija transakcija bude izvučena nasumičnim softverskim odabiranjem,
					a koji je kupio na EKO pumpama navedene proizvode iz člana 5. (motorni benzin “Racing Formula 98”
					ili dizel gorivo «Diesel Avio Double Filtered“) i to u minimalnoj količini od 15 litara uz
					provlačenje SMILE kartice za prikupljanje bodova i igranjem igrice na EKO SMILE.
				</p>
				<p className={styles.content}>
					6.5. Ukoliko Komisija iz člana 6.2. Pravila utvrdi da nasumično odabrana transakcija ispunjava sve
					uslove utvrđene ovim Pravilima, proglasiće učesnika u Nagradnoj igri – izvučenu transakciju
					dobitnikom nagrade.
				</p>
				<p className={styles.content}>
					6.6. U slučaju da Komisija iz člana 6.2. utvrdi da nasumično odabrana transakcija ne ispunjava sve
					uslove utvrđene ovim Pravilima, ili da se na osnovu kontakt podataka registrovanog člana i EKO SMILE
					kartice ne može nesporno utvrditi identitet dobitnika, proglasiće se takva transakcija nevažećom i
					umesto toga pristupiće se nasumičnom odabiranju druge transakcije, sve dok nasumično odabrana
					transakcija ne bude odgovarala svim uslovima utvrđenim ovim Pravilima.
				</p>
				<p className={styles.content}>
					6.7. Jedan učesnik u nagradnoj igri identifikovan sa imenom i prezimenom i svojim podacima preko Eko
					smile kartice i aplikacije može osvojiti više nagrada u nagradnoj igri.
				</p>
				<p className={styles.content}>
					6.8. Ona pravna lica koja na Eko pumpama kupuju goriva i proizvode Eko business karticama i koriste
					Eko Smile karticu za sakupljanje bodova i registrovanje transakcija su izuzeta iz mogućnosti za
					osvajanje nagrada.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>7. Nagradni fond</p>
				<p className={styles.content}>
					7.1. Ukupna vrednost nagradnog fonda u Nagradnoj igri iznosi 2.129.223,98 dinara, sa PDV-om. Srednji
					kurs evra na dan donošenja Odluke o priređivanju nagradne igre u robi i uslugama „EKO Nagradna igra“
					(4.10.2019.) iznosi 117.5208 dinara.
				</p>
				<p className={styles.content}>7.2. Nagradni fond u Nagradnoj igri se sastoji od nagrada i to:</p>
				<table className={styles.table}>
					<thead>
						<tr>
							<th>
								<p>
									<strong>Naziv</strong>
								</p>
							</th>
							<th>
								<p>
									<strong>Količina</strong>
								</p>
							</th>
							<th>
								<p>
									<strong>Bruto cena u RSD</strong>
								</p>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<p>Putovanje u Atinu sa posetom Olimpiji</p>
							</td>
							<td>
								<p>5</p>
							</td>
							<td>
								<p>1.406.723,98</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Mobilni telefon IPhone XR</p>
							</td>
							<td>
								<p>4</p>
							</td>
							<td>
								<p>384.000,00</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Kartica 100 litara goriva</p>
							</td>
							<td>
								<p>20</p>
							</td>
							<td>
								<p>338.500,00</p>
							</td>
						</tr>
						<tr>
							<td>
								<h2>UKUPNO</h2>
							</td>
							<td colSpan='2'>
								<p>2.129.223,98</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p className={styles.content}>
					Glavne nagrade: Putovanje u Atinu 5x2 osobe sa kompletno plaćenim porezom za isti, što obuhvata:
				</p>
				<ul>
					<li>10.03.2020. dolazak u Atinu letom JU 512, u 14:50č. Noćenje</li>
					<li>
						11.03.2020. Poseta Olimpiji i prisustvo na probi Paljenja Olimpijske bajklje. Povratak u Atinu u
						večernjim satima.
					</li>
					<li>12.03.2020. Obilazak Atine</li>
					<li>13.03.2020. Obilazak Muzeja i Hramova u Delfima. Povratak u Atinu u večernjim satima.</li>
					<li>14.03.2020. Doručak i slobodno vreme. Povratak za Beograd, letom JU 513 u 15:35č.</li>
					<li>
						Olimpija, Hotel Europa 4*{' '}
						<a
							className={styles.link}
							href='https://www.hoteleuropa.gr/'
							target='_blank'
							rel='noopener noreferrer'
						>
							www.hoteleuropa.gr
						</a>
					</li>
				</ul>
				<p className={styles.content}>
					7.3. Nagradni fond je ograničen i ni u kom slučaju neće biti dodeljeno više nagrada od broja
					predviđenim ovim Pravilnikom.
				</p>
				<p className={styles.content}>
					7.4. Rezultati izvlačenja nagrada sa imenima dobitnika u Nagradnoj igri biće objavljeni i na web
					sajtu{' '}
					<a
						className={styles.link}
						href='https://www.ekosmileklub.rs'
						target='_blank'
						rel='noopener noreferrer'
					>
						www.ekosmileklub.rs
					</a>{' '}
					i u dnevnom listu Informer dana 06.01.2020. godine što će uključiti celokupni nagradni fond.
					Nedeljne nagradne će biti objavlljene na društvenim mrežama Priređivača.
				</p>
				<p className={styles.content}>
					7.5. Priređivač se obavezuje da uruči izvučene nagrade dobitnicima u Nagradnoj igri najkasnije u
					roku od 10 dana od dana objavljivanja imena dobitnika na web sajtu{' '}
					<a
						className={styles.link}
						href='https://www.ekosmileklub.rs'
						target='_blank'
						rel='noopener noreferrer'
					>
						www.ekosmileklub.rs
					</a>{' '}
					i u dnevnom listu Informer.
				</p>
				<p className={styles.content}>
					7.6. Dobitnik u Nagradnoj igri je dužan da se u roku iz prethodnog stava javi Priređivaču na način
					objavljen istovremeno sa objavljivanjem rezultata izvlačenja u dnevnom listu. Momentom preuzimanja
					nagrade od strane dobitnika smatraće se da je Priređivač u celosti ispunio svoje obaveze prema
					dobitniku u skladu sa ovim Pravilima i zakonom.
				</p>
				<p className={styles.content}>
					U slučaju nepreuzimanja nagrade u roku iz člana 7.6., smatraće se da je Priređivač ispunio svoje
					obaveze i prema dobitnicima koji nisu preuzeli nagradu u ostavljenom roku.
				</p>
				<p className={styles.content}>
					7.7. Prilikom preuzimanja nagrade dobitnik je dužan da potvrdi svoj identitet važećom ličnom kartom
					kao i svojom EKO Smile karticom. Ukoliko je dobitnik nagrade u Nagradnoj igri osoba sa ograničenom
					ili oduzetom poslovnom sposobnošću, preuzimanje nagrade može izvršiti isključivu uz prezentovanu
					pisanu saglasnost staratelja ili u njegovom prisustvu.
				</p>
				<p className={styles.content}>
					7.8. Dodelu nagrada u Nagradnoj igri svim utvrđenim dobitnicima jemči Priređivač.
				</p>
				<p className={styles.content}>
					7.9. Dobitnik ne može zahtevati od Priređivača zamenu nagrade za novac.
				</p>
				<p className={styles.content}>
					7.10. Priređivač ne snosi troškove prevoza ili putovanja ili druge troškove dobitnika povezane sa
					preuzimanjem nagrada.
				</p>
				<p className={styles.content}>
					7.11. U slučaju da dobitnik nije u mogućnosti da preuzme i iskoristi osvojenu nagradu kod
					priređivača on može svoju osvojenu nagradu ustupiti licu koje navede, uz pismenu potvrdu istog, a u
					vezi glavne narade najkasnije 20 dana do putovanja.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>8. Ostale obaveze i odgovornosti Priređivača</p>
				<p className={styles.content}>
					8.1. Priređivač će po okončanju Nagradne igre u propisanom roku od 15 (petnaest) dana Ministarstvu
					finansija RS – Uprava za igre na sreću dostaviti obaveštenje o rezultatima Nagradne igre i podacima
					o dobitnicima u svemu u skladu sa zakonom.
				</p>
				<p className={styles.content}>
					8.2. Priređivač se obavezuje da sve lične podatke, kako učesnika nagradne igre tako i dobitnika
					nagrada, koristi isključivo u skladu sa Zakonom o zaštiti podataka o ličnosti.
				</p>
				<p className={styles.content}>
					8.3. Priređivač može prekinuti Nagradnu igru isključivo usled nastupanja okolnosti koje imaju
					karakter više sile, odnosno nastupanja drugih okolnosti koje se nisu mogle predvideti, izbeći ili
					otkloniti i na koje se nije moglo uticati. Priređivač se obavezuje da o svakom eventualnom prekidu
					Nagradne igre blagovremeno obavesti javnost putem medija.
				</p>
				<p className={styles.content}>
					8.4. Priređivač se obavezuje da u priređivanju Nagradne igre obezbedi sprovođenje svih pravila
					društvene odgovornosti i odgovorne igre u skladu sa zakonom, posebno u delu zaštite podataka o
					ličnosti učesnika.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>9. Porez</p>
				<p className={styles.content}>
					9.1. Priređivač se obavezuje da plati porez na dobitke od igara na sreću, u skladu sa Zakonom.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>10. Isključenje odgovornosti u sporovima</p>
				<p className={styles.content}>
					10.1. Priređivač neće snositi odgovornost za pretrpljenu materijalnu ili nematerijalnu štetu, koja
					nastane kao posledica učestvovanja u nagradnoj igri i osvajanju nagrada, izuzev odgovornosti koja se
					ne može zakonski izuzeti.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>11. Saglasnost</p>
				<p className={styles.content}>
					11.1. Učestvovanjem u nagradnoj igri svaki učesnik prihvata prava i obaveze iz ovih Pravila, s tim
					da dobitnik nagrade takođe pristaje:
				</p>
				<ul>
					<li>
						da se njegovi podaci o ličnosti koji su dostupni priređivaču čuvaju u skladu sa Zakonom o
						zaštiti podataka o ličnosti i da isti mogu biti korišćeni samo u marketinške svrhe priređivanja
						nagradne igre isključivo uz njihovu prethodnu saglasnost shodno Zakonu o zaštiti podataka o
						ličnosti,
					</li>
					<li>da se njegovo ime javno objavi u skladu sa pozitivnim propisima,</li>
					<li>
						da se njegovi lični podaci: ime, prezime, slika i video materijal, mogu od strane Priređivača
						koristiti na način da se objave u štampanom, zvučnom, slikovnom i video materijalu bez naknade,
						a isključivo u svrhe priređivanja ove nagradne igre i isključivo uz njihovu prethodnu saglasnost
						shodno Zakonu o zaštiti podataka o ličnosti.
					</li>
				</ul>
				<p className={styles.content}>
					Učesnici i dobitnik u ovoj nagradnoj igri saglasni su da je sav gore pomenuti materijal vlasništvo
					Priređivača, te se obavezuju da će se uzdržati od bilo kakvih postupaka prema priređivaču po osnovu
					prava korišćenja i/ili vlasništva navedenog materijala.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.title}>12. Završne odredbe</p>
				<p className={styles.content}>
					12.1. Na sve aspekte priređivanja Nagradne igre, koji eventualno nisu regulisani ovim Pravilima,
					neposredno se primenjuju odredbe Zakona o igrama sreću i drugih relevantnih pozitivnih propisa
					Republike Srbije.
				</p>
				<p className={styles.content}>
					12.2. Ova Pravila Priređivač objavljuje u dnevnom listu Informer u svemu u skladu sa zakonom.
				</p>
				<p className={styles.content}>
					12.3. Ova Pravila stupaju na snagu danom dobijanja saglasnosti ministra finansija.
				</p>
				<p className={styles.content}>12.4. U slučaju spora nadležan je sud u Beogradu.</p>
			</div>
			<hr />
			<h2 className={styles.olp_header}>OBAVEŠTENJE O OBRADI LIČNIH PODATAKA</h2>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Vaši lični podaci, odnosno ime i prezime, mobilni telefon i podaci o Vašem broju SMILE loyalty
					kartice („<strong>Lični podaci</strong>“) prikupljaju se i obrađuju od strane i/ili u ime i za račun
					privrednog društva <strong>EKO SERBIA AD</strong>, Tošin Bunar 274a, Beograd, MB: 17413333, PIB:
					100118236 („<strong>Društvo</strong>“) u svojstvu rukovaoca podataka. Društvo obrađuje Lične podatke
					u svrhu Vašeg učestvovanja i sprovođenja nagradne igre „EKO Nagradna igra“, u trajanju od 29.
					novembra 2019. godine do 28. decembra 2019. godine, pri čemu su Pravila nagradne igre unapred
					objavljena u skladu sa Zakonom o igrama na sreću.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Pored Društva, Ličnim podacima mogu da pristupe i da ih koriste i Druga lica, uključujući i ona koja
					se nalaze van Republike Srbije, i to u iste svrhe koje su navedene iznad, kao i nadležni državni
					organi u zakonom predviđenim slučajevima (npr. Uprava za igre na sreću). Pored toga, Društvo i
					njegova povezana društva mogu da, radi ostvarenja gore-navedenih svrha obrada, Lične podatke
					dostavljaju (ili da omogućavaju pristup Ličnim podacima) trećim licima koja će Lične podatke moći da
					obrađuju isključivo kao obrađivači podataka, odnosno u ime Društva i/ili povezanih društava i u
					skladu sa instrukcijama Društva i/ili povezanih društava.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Ukoliko se Lični podaci budu transferisali van Republike Srbije u neku od država koja ne obezbeđuje
					primereni nivo zaštite podataka o ličnosti u skladu sa Zakonom o zaštiti podataka o ličnosti
					Republike Srbije („Sl. glasnik RS“, br. 87/2018; u daljem tekstu: „<strong>Zakon</strong>“), Društvo
					će ispuniti sve relevantne uslove i preduzeti sve mere potrebne kako bi se obezbedile odgovarajuće
					garancije u pogledu obrade Ličnih podataka i kako bi se obezbedio primereni nivo zaštite. Za više
					detalja o primenjenim merama dopisom na gore-navedenu adresu Društva.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Pravni osnov za obradu Ličnih podataka je Vaš pristanak na učestvovanje u nagradnoj igri kao i
					poštovanje pravnih obaveza Društva u skladu sa Zakonom o igrama na sreću.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Društvo će čuvati Lične podatke onoliko vremena koliko je potrebno za ostvarivanje gore-navedenih
					svrha obrade Ličnih podataka ili do povlačenja saglasnosti za obradu Ličnih podataka ukoliko
					odlučite da povučete svoju prethodno datu saglasnost. Dodatno, u skladu sa Zakonom o igrama na sreću
					Društvo će čuvati podatke o dobitnicima nagradne igre 5 (pet) godina od završetka nagradne igre.
					Podaci o dobitnicima će biti javno objavljeni i dostupni na socijalnim mrežama Društva i dostavljeni
					Upravi za igre na sreću.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					U skladu sa Zakonom predviđenim uslovima, imate određena prava u vezi sa obradom Vaših Ličnih
					podataka, i to: pravo da zatražite informacije o njihovoj obradi i da im pristupite, da tražite
					njihovu kopiju, ispravku, dopunu ili brisanje, ograničenje njihove obrade, da prigovorite njihovoj
					obradi i pravo na prekid njihove obrade, kao i, ukoliko je primenjivo, pravo na prijem ili prenos
					podataka u strukturiranom, uobičajeno korišćenom i elektronski čitljivom formatu, i pravo da se na
					Vas ne primenjuje odluka doneta isključivo na osnovu automatizovane obrade.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Ukoliko je pravni osnov za obradu Ličnih podataka saglasnost, lice na koje se podaci odnose ima
					pravo da tu saglasnost povuče u svakom trenutku, s tim da povlačenje ne utiče na zakonitost obrade
					koja je vršena pre tog povlačenja. Nakon povlačenja saglasnosti, obrada podataka od strane Društva
					više nije dozvoljena osim ukoliko za nju postoji drugi pravni osnov.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Društvo je obavezno da obezbedi da se gore-navedena prava poštuju i da se omogući njihovo
					ostvarivanje u Zakonom predviđenim rokovima.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					U slučaju bilo kakve nedozvoljene obrade Ličnih podataka od strane Društva, imate pravo na zakonom
					predviđenu pravnu zaštitu, uključujući i pravo na podnošenje pritužbe Povereniku za informacije od
					javnog značaja i zaštitu podataka o ličnosti, kao i pravo na obraćanje sudu radi zaštite svojih
					prava.
				</p>
			</div>
			<div className={styles.about_list_item}>
				<p className={styles.content}>
					Ako imate bilo koje pitanje u vezi sa ovim Obaveštenjem ili ako želite da zatražite ostvarenje bilo
					kog od gore-navedenih prava, možete nam se obratiti na gore-navedenu adresu Društva.
				</p>
			</div>
		</div>
	);
};

export default GameTermsSR;
