import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';

import Button from '../UI/Button/Button';

import app_store from '../../assets/images/app_store_badge.svg';
import google_play from '../../assets/images/google_play_badge.png';
import smile from '../../assets/images/smileCard.png';
import styles from './Choice.module.scss';

class Choice extends Component {
	state = {
		help: false,
	};

	handleForward = choice => {
		if (choice === 'user') {
			this.props.history.push('/login');
		}
		if (choice === 'new_user') {
			this.props.history.push('/register');
		}
	};

	onToggleHelpHandler = () => {
		this.setState(prevState => ({
			help: !prevState.help,
		}));
	};
	render() {
		let help = [styles.btn_flip];
		if (this.state.help) {
			help.push(styles.rotate);
		}

		return (
			<div className={styles.choice}>
				<img className={styles.img} src={smile} alt='smile' />
				<Button btnType='btn_regular_iconright' clicked={() => this.handleForward('user')}>
					Postojeći korisnik
					<i className='material-icons icon arrow_forward'></i>
				</Button>
				<p>Nemate karticu?</p>
				<Button btnType='btn_preffered_iconright' clicked={() => this.handleForward('new_user')}>
					Novi korisnik
					<i className='material-icons icon arrow_forward'></i>
				</Button>
				<div className={styles.info}>
					<p className={styles.title}>Skinite aplikaciju na Vaš mobilni uređaj</p>
					<div className={styles.download}>
						<a href='https://play.google.com/store/apps/details?id=com.ekosmile' target='_blanc'>
							<img className={styles.google} src={google_play} alt='' />
						</a>
						<a href='https://itunes.apple.com/us/app/gun-deals/id1444737867?mt=8' target='_blanc'>
							<img className={styles.app_store} src={app_store} alt='' />
						</a>
					</div>
				</div>
				<div className={styles.bottom}>
					{/* <p>Nemate karticu?</p>
					<Button btnType='btn_regular_iconright' clicked={this.onGuestLogin.bind(this)}>
						Koristite aplikaciju kao gost
						<i className='material-icons icon arrow_forward'></i>
					</Button> */}

					<div className={styles.flip_continer} onClick={this.onToggleHelpHandler}>
						<div className={help.join(' ')}>
							<div className={styles.btn}>
								<Button btnType='btn_regular_iconright'>
									Pomoć
									<i className='material-icons icon help_outline'></i>
								</Button>
							</div>
							<div className={styles.help}>
								Za pomoć molimo pozovite
								<br />
								<span className={styles.phone_icon}>
									<i className='material-icons phone'></i>
									&nbsp;0800 088 887
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Choice);
