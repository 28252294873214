import React from 'react';

import styles from '../../UI/Button/Button.module.scss';

const Button = ({ disabled, btnType, clicked, children }) => {
	return (
		<button disabled={disabled} className={styles[btnType]} onClick={clicked}>
			{children}
		</button>
	);
};

export default Button;
