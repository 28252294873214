import * as actionTypes from './actionTypes';

export const onSetUser = user => {
	return {
		type: actionTypes.SET_USER,
		user: user,
	};
};

export const onSetPoints = points => {
	return {
		type: actionTypes.SET_POINTS,
		points: points,
	};
};

export const onSetUserType = userType => {
	return {
		type: actionTypes.SET_USER_TYPE,
		userType: userType,
	};
};

export const onSetTouched = () => {
	return {
		type: actionTypes.SET_TOUCHED,
	};
};

export const setUser = user => dispatch => dispatch(onSetUser(user));
export const setPoints = points => dispatch => dispatch(onSetPoints(points));
export const setUserType = userType => dispatch => dispatch(onSetUserType(userType));
export const setTouched = () => dispatch => dispatch(onSetTouched());
