import MDSpinner from 'react-md-spinner';
import React from 'react';

import styles from './Spinner.module.scss';

const Spinner = () => {
	return (
		<div className={styles.spinner}>
			<MDSpinner size='42px' singleColor='#DB052C' borderSize={2} />
		</div>
	);
};

export default Spinner;
