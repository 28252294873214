import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
	user: null,
	points: null,
	userType: null,
	touched: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER:
			return updateObject(state, { user: action.user });
		case actionTypes.SET_POINTS:
			return updateObject(state, { points: action.points });
		case actionTypes.SET_USER_TYPE:
			return updateObject(state, { userType: action.userType });
		case actionTypes.SET_TOUCHED:
			return updateObject(state, { touched: true });
		default:
			return state;
	}
};

export default reducer;
