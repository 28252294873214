import axios from 'axios';

const instance = axios.create({
	baseURL: '//api.ekosmileklub.rs/EkoAPI/api/values',
	headers: {
		jezik: 'sr',
		platforma: 'Web',
	},
	// baseURL: 'https://api.ekosmileklub.rs/EkoAPI/api/values',
	// headers: {
	// 	jezik: 'sr',
	// 	platforma: 'Web',
	// },
	// baseURL: 'https://api.ekosmileklub.rs/EkoAPITest/api/values',
	// headers: {
	// 	jezik: 'sr',
	// 	platforma: 'Web',
	// },
});

export default instance;
