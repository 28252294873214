import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import crypto from 'crypto-js';
import React, { Component, lazy, Suspense } from 'react';

import { CRYPTO_KEY } from './shared/keys';
import * as actions from './store/actions';
import axios from './axios';
import Choice from './components/Choice/Choice';
import Spinner from './components/UI/Spinner/Spinner';
import GameTermsEN from './mobile/GameTermsEN/GameTermsEN';
import GameTermsSR from './mobile/GameTermsSR/GameTermsSR';

const Login = lazy(() => import('./containers/Login/Login'));
const Register = lazy(() => import('./containers/Register/Register'));
const Layout = lazy(() => import('./containers/Layout/Layout'));
const Catalog = lazy(() => import('./components/Catalog/Catalog'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Product = lazy(() => import('./components/Product/Product'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Trending = lazy(() => import('./components/Trending/Trending'));
// const QRcode = lazy(() => import('./components/QRcode/QRcode'));
const Article = lazy(() => import('./components/Trending/Article/Article'));
const SpecialOffer = lazy(() => import('./components/Trending/SpecialOffer/SpecialOffer'));
const Voting = lazy(() => import('./components/Trending/Voting/Voting'));
const VotingResults = lazy(() => import('./components/Trending/VotingResults/VotingResults'));
const Activities = lazy(() => import('./components/Activities/Activities'));
// const Coupons = lazy(() => import('./components/Coupons/Coupons'));
// const Coupon = lazy(() => import('./components/Coupons/Coupon/Coupon'));
const WishList = lazy(() => import('./components/WishList/WishList'));
const TransferPoints = lazy(() => import('./components/TransferPoints/TransferPoints'));
// const Share = lazy(() => import('./components/Share/Share'));
const Settings = lazy(() => import('./components/Settings/Settings'));
const Map = lazy(() => import('./components/Map/Map'));
const About = lazy(() => import('./components/About/About'));
const FAQ = lazy(() => import('./components/FAQ/FAQ'));
const PrizesList = lazy(() => import('./components/PrizesList/PrizesList'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions/TermsAndConditions'));
const BlockCard = lazy(() => import('./components/BlockCard/BlockCard'));
const Premium = lazy(() => import('./components/Premium/Premium'));
const TermsAndConditionsSR = lazy(() => import('./mobile/TermsAndConditionsSR/TermsAndConditionsSR'));
const TermsAndConditionsEN = lazy(() => import('./mobile/TermsAndConditionsEN/TermsAndConditionsEN'));
const AboutSR = lazy(() => import('./mobile/AboutSR/AboutSR'));
const AboutEN = lazy(() => import('./mobile/AboutEN/AboutEN'));

class App extends Component {
	state = {
		logedIn: true,
		userType: null,
	};

	componentDidMount = async () => {
		if (!sessionStorage.getItem('s_uid')) {
			try {
				const response = await axios.get('/GetPoeni?partnerid=0');

				const { data } = response.data;

				const points = {
					ptsToPremium: data.poeni_do_premium,
					limit: data.limit_za_premium,
					points: data.value,
				};
				this.props.onSetPoints(points);
			} catch (error) {
				console.log(error);
			}
		}
		this.onLoggedIn();
	};

	onLoggedIn = async () => {
		if (!sessionStorage.getItem('s_uid')) {
			this.props.onSetUserType('guest');
			this.setState({ logedIn: false });
		} else {
			try {
				const bytes = crypto.AES.decrypt(sessionStorage.getItem('s_uid').toString(), CRYPTO_KEY);
				const user_id = JSON.parse(bytes.toString(crypto.enc.Utf8));

				const response = await axios.get(`/GetUserDetails?partnerid=${user_id}`);

				const { data } = response.data;

				const user = {
					address: data.user.Adresa,
					cardId: data.user.BrojKartice,
					qrCode: data.user.BrojKarticeZaQR,
					otherReferal: data.user.DozvoliDaUneseTudjiReferal,
					email: data.user.Email,
					city: data.user.Grad,
					name: data.user.Ime,
					phone: data.user.Mobilni,
					userId: data.user.GUID,
					zipCode: data.user.PostanskiBroj,
					premium: data.user.Premium,
					lastName: data.user.Prezime,
					friendReferal: data.user.PrijateljReferal,
					referal: data.user.Referal,
					signed: data.user.Signed,
				};
				const points = {
					ptsToPremium: data.user.poeni.poeni_do_premium,
					limit: data.user.poeni.limit_za_premium,
					points: data.user.poeni.value,
					qualificationPoints: data.user.poeni.poeni_u_toku_kvalifikacionog_perioda,
				};
				this.props.onSetPoints(points);
				this.props.onSetUser(user);
				if (data.user.Premium) {
					this.props.onSetUserType('premium');
				} else {
					this.props.onSetUserType('standard');
				}
				this.setState({ logedIn: true });
			} catch (error) {
				console.log(error);
			}
		}
	};

	render() {
		const { logedIn } = this.state;
		const { userType } = this.props;
		let routes = null;

		if (logedIn) {
			routes = (
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route
							path='/choice'
							exact
							render={() => (
								<Suspense fallback={<Spinner />}>
									<Choice />
								</Suspense>
							)}
						/>
						<Route
							path='/login'
							exact
							render={() => (
								<Suspense fallback={<Spinner />}>
									<Login login={this.onLoggedIn} />
								</Suspense>
							)}
						/>
						<Route
							path='/register'
							exact
							render={() => (
								<Suspense fallback={<Spinner />}>
									<Register login={this.onLoggedIn} />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/sr/EYPNMCNTCGHNGPZNPAAQ'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<TermsAndConditionsSR />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/en/MVMDNIAXNTAXETTHHPFD'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<TermsAndConditionsEN />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/about/sr/WYXVCNSYQFKXFOGJNWKD'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<AboutSR />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/about/en/OECWGMSOSELLHOVVBEOB'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<AboutEN />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/game/sr/RJLLUYXYSJPJUCTTKFMS'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<GameTermsSR />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/game/en/ZAGRKUJITHFDGVUFGRVW'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<GameTermsEN />
								</Suspense>
							)}
						/>
						<Layout>
							<Switch>
								<Route
									exact
									path='/catalog'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Catalog />
										</Suspense>
									)}
								/>
								<Route
									path='/catalog/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Product />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/trending'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Trending />
										</Suspense>
									)}
								/>
								<Route
									path='/trending/article/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Article />
										</Suspense>
									)}
								/>
								<Route
									path='/trending/special-offer/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<SpecialOffer />
										</Suspense>
									)}
								/>
								<Route
									path='/trending/voting/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Voting />
										</Suspense>
									)}
								/>
								<Route
									path='/trending/voting-results/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<VotingResults />
										</Suspense>
									)}
								/>
								{/* <Route exact path='/qrcode' render={ () => (<Suspense fallback={ <Spinner/> }><QRcode /></Suspense>) } /> */}
								<Route
									exact
									path='/activities'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Activities />
										</Suspense>
									)}
								/>
								{/* <Route exact path='/my-coupons' render={ () => (<Suspense fallback={ <Spinner/> }><Coupons /></Suspense>) } /> */}
								{/* <Route path='/my-coupons/:id' render={ () => (<Suspense fallback={ <Spinner/> }><Coupon /></Suspense>) } /> */}
								<Route
									exact
									path='/wishlist'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<WishList />
										</Suspense>
									)}
								/>
								<Route
									path='/wishlist/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Product />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/transfer-points'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<TransferPoints />
										</Suspense>
									)}
								/>
								{/* <Route exact path='/share' render={ () => (<Suspense fallback={ <Spinner/> }><Share /></Suspense>) } /> */}
								<Route
									exact
									path='/settings'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Settings />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/map'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Map />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/contact'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Contact />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/about'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<About />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/faq'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<FAQ />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/prizes_list'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<PrizesList />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/premium'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Premium />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/terms-and-conditions'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<TermsAndConditions />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/block-card'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<BlockCard />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Dashboard />
										</Suspense>
									)}
								/>
								<Route
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Dashboard />
										</Suspense>
									)}
								/>
							</Switch>
						</Layout>
					</Switch>
				</Suspense>
			);
		} else if (userType === 'guest') {
			routes = (
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route
							path='/choice'
							exact
							render={() => (
								<Suspense fallback={<Spinner />}>
									<Choice />
								</Suspense>
							)}
						/>
						<Route
							path='/login'
							exact
							render={() => (
								<Suspense fallback={<Spinner />}>
									<Login login={this.onLoggedIn} />
								</Suspense>
							)}
						/>
						<Route
							path='/register'
							exact
							render={() => (
								<Suspense fallback={<Spinner />}>
									<Register login={this.onLoggedIn} />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/sr/EYPNMCNTCGHNGPZNPAAQ'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<TermsAndConditionsSR />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/en/MVMDNIAXNTAXETTHHPFD'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<TermsAndConditionsEN />
								</Suspense>
							)}
						/>

						<Route
							exact
							path='/mobile/about/sr/WYXVCNSYQFKXFOGJNWKD'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<AboutSR />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/about/en/OECWGMSOSELLHOVVBEOB'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<AboutEN />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/game/sr/RJLLUYXYSJPJUCTTKFMS'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<GameTermsSR />
								</Suspense>
							)}
						/>
						<Route
							exact
							path='/mobile/tc/game/en/ZAGRKUJITHFDGVUFGRVW'
							render={() => (
								<Suspense fallback={<Spinner />}>
									<GameTermsEN />
								</Suspense>
							)}
						/>
						<Layout>
							<Switch>
								<Route
									exact
									path='/catalog'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Catalog />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/catalog/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Product />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/trending'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Trending />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/trending/article/:id'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Article />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/about'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<About />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/faq'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<FAQ />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/premium'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Premium />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/map'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Map />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/contact'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Contact />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/terms-and-conditions'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<TermsAndConditions />
										</Suspense>
									)}
								/>
								<Route
									exact
									path='/'
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Dashboard />
										</Suspense>
									)}
								/>
								<Route
									render={() => (
										<Suspense fallback={<Spinner />}>
											<Dashboard />
										</Suspense>
									)}
								/>
							</Switch>
						</Layout>
					</Switch>
				</Suspense>
			);
		}

		return routes;
	}
}

const mapStateToProps = state => {
	return {
		userType: state.user.userType,
	};
};

const mapDispatchToProps = {
	onSetUser: user => actions.setUser(user),
	onSetPoints: points => actions.setPoints(points),
	onSetUserType: userType => actions.setUserType(userType),
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(App),
);
