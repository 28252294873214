// Unmutable object cloning

export const updateObject = (oldObject, newProps) => {
	return {
		...oldObject,
		...newProps,
	};
};

// Calculating % from limit for premium and points to premium

export const calcPct = (ptsTo, limit) => {
	if (ptsTo > limit && (ptsTo !== 0 && limit !== 0)) {
		return 100;
	}
	if (limit - ptsTo === 0) {
		return 0;
	} else {
		return Math.round(((limit - ptsTo) * 100) / limit);
	}
};

// Calculating %

export const pct = (ptsTo, limit) => {
	if (ptsTo >= limit && (ptsTo !== 0 && limit !== 0)) {
		return 100;
	} else {
		return Math.round((100 * ptsTo) / limit);
	}
};

// Form validation helper

export const checkValidity = (value, rules) => {
	// let isValid = true;
	// let message = '';
	let val = {
		isValid: true,
		message: '',
	};

	if (!rules) {
		return true;
	}

	if (rules.required) {
		val.isValid = value.trim() !== '' && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'Obavezan unos podataka';
		}
	}

	if (rules.min) {
		val.isValid = value >= rules.min && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = `Najmanji iznos je ${rules.min}`;
		}
	}

	if (rules.max) {
		val.isValid = value <= rules.max && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = `Najveći iznos je ${rules.max} poena`;
		}
	}

	if (rules.isEmail) {
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		val.isValid = pattern.test(value) && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'Unesite validan mail';
		}
	}

	if (rules.isJMBG) {
		const pattern = /^\d{13}$/;
		val.isValid = pattern.test(value) && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'JMBG sadrži 13 brojeva';
		}
	}

	if (rules.isNumeric) {
		const pattern = /^[0-9]+$/;
		val.isValid = pattern.test(value) && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'Polje sadrži samo brojeve';
		}
	}

	if (rules.isStreet) {
		const pattern = /^([a-ša-zA-ZA-Š]+\s)+(\d)+([\d\w/]+)*$/;
		val.isValid = pattern.test(value) && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'Unesite validnu adresu';
		}
	}

	if (rules.isPhone) {
		const pattern = /^\+{1}\d+$/;
		val.isValid = pattern.test(value) && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'Unesite validan broj telefona';
		}
	}

	if (rules.isCardOrEmail) {
		const pattern_card = /^\d{17}$/;
		const pattern_mail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		val.isValid = (pattern_card.test(value) && val.isValid) || (pattern_mail.test(value) && val.isValid);
		if (val.message === '' && !val.isValid) {
			val.message = 'Unesite validne podatke';
		}
	}

	if (rules.isPIN) {
		const pattern = /^\d{4}$/;
		val.isValid = pattern.test(value) && val.isValid;
		if (val.message === '' && !val.isValid) {
			val.message = 'PIN mora sadržati 4 cifre';
		}
	}

	return val;
};
